// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-android-js": () => import("./../../../src/pages/android.js" /* webpackChunkName: "component---src-pages-android-js" */),
  "component---src-pages-company-en-js": () => import("./../../../src/pages/company/en.js" /* webpackChunkName: "component---src-pages-company-en-js" */),
  "component---src-pages-company-jp-js": () => import("./../../../src/pages/company/jp.js" /* webpackChunkName: "component---src-pages-company-jp-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-inquiry-js": () => import("./../../../src/pages/inquiry.js" /* webpackChunkName: "component---src-pages-inquiry-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-redirect-js": () => import("./../../../src/pages/redirect.js" /* webpackChunkName: "component---src-pages-redirect-js" */),
  "component---src-pages-service-js": () => import("./../../../src/pages/service.js" /* webpackChunkName: "component---src-pages-service-js" */),
  "component---src-pages-warehouse-js": () => import("./../../../src/pages/warehouse.js" /* webpackChunkName: "component---src-pages-warehouse-js" */)
}

